export function replaceTextLanguage(currentCountryCode, Text, Type) {
    if (!Text) {
        return Text;
    }
    if (!currentCountryCode) {
        return Text;
    }
    let replacedText = Text
        .replace(new RegExp('/' + currentCountryCode + "\\/sites", "g"), "/sites")
        .replace(new RegExp('/' + currentCountryCode + "\\/external", "g"), "/external")
        .replace(new RegExp('/' + currentCountryCode + "tel", "g"), "tel")
        .replace(new RegExp('/' + currentCountryCode + "Tel", "g"), "Tel")
        .replace(new RegExp('/' + currentCountryCode + "mailto", "g"), "mailto")
        .replace(new RegExp('/' + currentCountryCode + "#", "g"), "#")  //href="/ie#human-centric-tech"
        .replace(new RegExp('/' + currentCountryCode + "ht", "g"), "ht")
        .replace(new RegExp('/' + currentCountryCode + "\\//", "g"), "") //href="/de-en//www.en.greatplacetowork.ch/workplace/item/2988/Packimpex+AG"
        ;
    if (replacedText.charAt(0) !== '/' && Type !== 'html') {
        replacedText = `https://${replacedText}`;
    }
    return replacedText
}

export function replaceTextGreenArrowIcon(Text) {
    if (!Text) {
        return Text;
    }

    let replacedText = Text
        .replace(/<ul>/g, '')    // 删除 <ul> 标签
        .replace(/<\/ul>/g, '') // 删除 </ul> 标签
        .replace(/<li>/g, '<div class="cont-item"><span><img alt="item-svg" class="cont-item-svg" height="15" src="/sites/default/files/2023-07/green-arrow-icon.svg" width="8"></span><div>') // 替换 <li> 开始标签
        .replace(/<\/li>/g, '</div></div>'); // 替换 </li> 结束标签

    return replacedText
}